import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogImage?: string;
  ogUrl?: string;
  ogType?: string;
  canonical?: string;
}

const SEO: React.FC<SEOProps> = ({
  title = 'Daktilo Dijital - Dijital Pazarlama ve Çözümler',
  description = 'Daktilo Dijital ile markanızı dijital dünyada güçlendirin. Web tasarım, sosyal medya yönetimi, içerik üretimi ve dijital pazarlama çözümleri sunuyoruz.',
  keywords = 'dijital pazarlama, web tasarım, sosyal medya yönetimi, seo, içerik üretimi, dijital çözümler',
  ogImage = '/og-image.jpg',
  ogUrl = 'https://daktilodijital.com',
  ogType = 'website',
  canonical = 'https://daktilodijital.com',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Canonical Link */}
      <link rel="canonical" href={canonical} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImage} />
      
      {/* Mobile Responsive Meta Tag */}
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      
      {/* Additional SEO meta tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="Turkish" />
      <meta charSet="UTF-8" />
      
      {/* Performance related tags */}
      <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
    </Helmet>
  );
};

export default SEO;
