import React from 'react';
import './PolicyPage.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const KVKKPolicy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="policy-page">
        <div className="policy-header">
          <h1>KVKK AYDINLATMA METNİ</h1>
          <div className="policy-date">Kişisel Verilerin Korunması Kanunu (KVKK) Kapsamında Aydınlatma Metni</div>
        </div>

        <p>
          Daktilo Dijital Ajans olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca, 
          tarafımıza sağladığınız kişisel verilerin işlenmesi hakkında sizi bilgilendirmek isteriz.
        </p>

        <div className="policy-section">
          <h2 className="section-title">1. Veri Sorumlusu</h2>
          <p>
            Bu aydınlatma metni, veri sorumlusu sıfatıyla Daktilo Dijital Ajans tarafından hazırlanmıştır.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">2. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</h2>
          <p>
            Kişisel verileriniz, web sitemizdeki formlar, e-posta iletişimi ve sözleşmeler yoluyla elektronik ortamda toplanmaktadır. 
            KVKK'nın 5. ve 6. maddelerinde belirtilen hukuki sebepler doğrultusunda işlenir.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">3. İşlenen Kişisel Veriler</h2>
          <ul>
            <li>Ad, soyad</li>
            <li>Telefon numarası</li>
            <li>E-posta adresi</li>
            <li>IP adresi, cihaz bilgileri</li>
            <li>Görsel/veri yüklemeleri (örneğin proje dosyaları)</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="section-title">4. Verilerin İşlenme Amaçları</h2>
          <ul>
            <li>Hizmet sunumu ve sözleşme süreçlerinin yürütülmesi</li>
            <li>İletişim faaliyetlerinin sağlanması</li>
            <li>Yasal yükümlülüklerin yerine getirilmesi</li>
            <li>Pazarlama ve analiz çalışmaları (onay verilmesi halinde)</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="section-title">5. Haklarınız</h2>
          <p>KVKK uyarınca aşağıdaki haklara sahipsiniz:</p>
          <ul>
            <li>Kişisel verinizin işlenip işlenmediğini öğrenme</li>
            <li>Hangi verilerin işlendiğini öğrenme</li>
            <li>Düzeltme, silme veya yok edilmesini talep etme</li>
            <li>İşleme itiraz etme</li>
          </ul>
          <p>Bu haklarınızı kullanmak için bize <a href="mailto:info@daktilodijital.com">info@daktilodijital.com</a> adresinden ulaşabilirsiniz.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KVKKPolicy;
