import React from 'react';
import { Helmet } from 'react-helmet';

const LocalBusinessSchema: React.FC = () => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Daktilo Dijital',
    'url': 'https://daktilodijital.com',
    'logo': 'https://daktilodijital.com/logo.png',
    'description': 'Daktilo Dijital ile markanızı dijital dünyada güçlendirin. Web tasarım, sosyal medya yönetimi, içerik üretimi ve dijital pazarlama çözümleri.',
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': 'Adres Satırı',
      'addressLocality': 'İstanbul',
      'addressRegion': 'İstanbul',
      'postalCode': '34000',
      'addressCountry': 'TR'
    },
    'email': 'info@daktilodijital.com',
    'telephone': '+905551234567',
    'sameAs': [
      'https://www.instagram.com/daktilodijital',
      'https://www.linkedin.com/company/daktilodijital'
    ],
    'openingHoursSpecification': {
      '@type': 'OpeningHoursSpecification',
      'dayOfWeek': [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday'
      ],
      'opens': '09:00',
      'closes': '18:00'
    },
    'hasOfferCatalog': {
      '@type': 'OfferCatalog',
      'name': 'Dijital Çözümler',
      'itemListElement': [
        {
          '@type': 'Offer',
          'itemOffered': {
            '@type': 'Service',
            'name': 'Web Tasarım ve Yazılım',
            'description': 'Profesyonel web tasarım ve yazılım hizmetleri'
          }
        },
        {
          '@type': 'Offer',
          'itemOffered': {
            '@type': 'Service',
            'name': 'Sosyal Medya Yönetimi',
            'description': 'Sosyal medya hesaplarınızın profesyonel yönetimi'
          }
        },
        {
          '@type': 'Offer',
          'itemOffered': {
            '@type': 'Service',
            'name': 'SEO ve SEM',
            'description': 'Arama motoru optimizasyonu ve arama motoru pazarlaması'
          }
        }
      ]
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default LocalBusinessSchema;
