import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/images/daktilo.png';

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Sayfa içi kaydırma fonksiyonu
  const scrollToSection = (sectionId: string, event: React.MouseEvent) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    
    if (section) {
      // Menü açıksa kapat
      if (menuOpen) {
        setMenuOpen(false);
      }

      // Belirtilen bölüme sorunsuz kaydırma
      section.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <header className={`navbar ${scrolled ? 'scrolled' : ''} ${menuOpen ? 'menu-open' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} className="logo" alt="Daktilo Dijital" />
          </Link>
        </div>
        
        <nav className={`navbar-menu ${menuOpen ? 'active' : ''}`}>
          <ul>
            <li><Link to="/" className="nav-link">Ana Sayfa</Link></li>
            <li><a href="#services" className="nav-link" onClick={(e) => scrollToSection('services', e)}>Hizmetler</a></li>
            <li><a href="#digital-solutions" className="nav-link" onClick={(e) => scrollToSection('digital-solutions', e)}>Dijital Çözümlerimiz</a></li>
            <li><a href="#references" className="nav-link" onClick={(e) => scrollToSection('references', e)}>Referanslar</a></li>
            <li><a href="#blog" className="nav-link" onClick={(e) => scrollToSection('blog', e)}>Blog</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
