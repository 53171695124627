import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabase/supabaseClient';
import './Dashboard.css';

// Görsel veri tipi
interface ImageItem {
  id: string;
  url: string;
}

// Referans veri tipi
interface Reference {
  id: string;
  created_at: string;
  image_url: string;
  images?: ImageItem[];
  is_white_background: boolean;
  order?: number;
}

// ReferenceManager props
interface ReferenceManagerProps {
  user: any;
}

// Yeni referans için default değerler
const initialFormState: Omit<Reference, 'id' | 'created_at'> = {
  image_url: '',
  images: [],
  is_white_background: false,
  order: 0
};

const ReferenceManager: React.FC<ReferenceManagerProps> = ({ user }) => {
  const [references, setReferences] = useState<Reference[]>([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [currentImageId, setCurrentImageId] = useState<string | null>(null); // Düzenlenen veya silinen görsel ID'si

  // Referansları yükle
  useEffect(() => {
    fetchReferences();
  }, []);

  // Tümünü seçme durumunu kontrol et
  useEffect(() => {
    if (references.length > 0 && selectedItems.length === references.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedItems, references]);

  const fetchReferences = async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('references')
        .select('*')
        .order('order', { ascending: true });
      
      if (error) throw error;
      
      if (data) {
        setReferences(data as Reference[]);
      }
    } catch (error: any) {
      console.error('Referanslar yüklenirken hata:', error.message);
      setError('Referanslar yüklenirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  // Form değişikliklerini işleme
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Dosya seçme işlemi
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      setSelectedFiles(files);
      
      // Çoklu görsel önizleme oluştur
      const previewsArray: string[] = [];
      files.forEach(file => {
        const imageUrl = URL.createObjectURL(file);
        previewsArray.push(imageUrl);
      });
      setPreviewUrls(previewsArray);
    } else {
      setSelectedFiles([]);
      setPreviewUrls([]);
    }
  };
  
  // Önizleme görsellerini temizle
  useEffect(() => {
    // Component unmount olduğunda URL.createObjectURL'leri temizle
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);

  // Tüm görselleri Supabase'e yükle
  const uploadImages = async (): Promise<ImageItem[]> => {
    if (!selectedFiles.length) return [];
    
    try {
      setIsUploading(true);
      setUploadProgress(0);
      
      const imageItems: ImageItem[] = [];
      const totalFiles = selectedFiles.length;
      
      // Her bir dosya için ilerlemeyi hesapla
      const fileProgressStep = 100 / totalFiles;
      
      // Her dosyayı sırayla yükle
      for (let i = 0; i < totalFiles; i++) {
        const file = selectedFiles[i];
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${fileExt}`;
        const filePath = `references/${fileName}`;
        
        // Yükleme animasyonu için ilerleme güncelle
        setUploadProgress((i * fileProgressStep));
        
        // Supabase Storage'a dosya yükleme
        const { data, error } = await supabase.storage
          .from('images')
          .upload(filePath, file, {
            cacheControl: '3600',
            upsert: false
          });
        
        if (error) throw error;
        
        // Yüklenen dosyanın URL'sini al
        const { data: urlData } = supabase.storage.from('images').getPublicUrl(filePath);
        
        // Yeni görsel öğesi oluştur ve listele ekle
        const newImage: ImageItem = {
          id: Math.random().toString(36).substring(2, 15) + Date.now().toString(36),
          url: urlData.publicUrl
        };
        
        imageItems.push(newImage);
      }
      
      // Tüm dosyalar yüklendi, %100 ilerleme göster
      setUploadProgress(100);
      
      return imageItems;
    } catch (error: any) {
      console.error('Dosya yüklenirken hata:', error.message);
      setError('Dosya yüklenirken bir hata oluştu: ' + error.message);
      return [];
    } finally {
      setIsUploading(false);
    }
  };

  // Referans kaydetme/güncelleme
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    
    try {
      // Veri hazırlığı için mevcut verileri kopyala
      let updatedFormData = { ...formData };
      
      // Eğer yeni dosyalar seçilmişse onları yükle
      if (selectedFiles.length) {
        const uploadedImages = await uploadImages();
        
        if (uploadedImages.length === 0 && selectedFiles.length > 0) {
          return; // Dosya yükleme hatası olmuşsa işlemi durdur
        }
        
        // Eğer düzenleme modundaysak mevcut görsellere ekle, değilse yeni liste oluştur
        if (isEditing && formData.images) {
          updatedFormData.images = [...formData.images, ...uploadedImages];
        } else {
          updatedFormData.images = uploadedImages;
        }
        
        // Ana görsel URL'si için ilk görseli kullan (geriye dönük uyumluluk)
        if (uploadedImages.length > 0) {
          updatedFormData.image_url = uploadedImages[0].url;
        }
      }
      
      if (isEditing && editId) {
        // Mevcut referansı güncelle
        const { error } = await supabase
          .from('references')
          .update({
            image_url: updatedFormData.image_url,
            images: updatedFormData.images,
            is_white_background: updatedFormData.is_white_background,
            order: updatedFormData.order || 0
          })
          .match({ id: editId });
        
        if (error) throw error;
        
        setSuccessMessage('Referans başarıyla güncellendi!');
        
        // Düzenleme modunu kapat
        resetForm();
      } else {
        // Yeni referans ekle
        const { error } = await supabase
          .from('references')
          .insert([{
            image_url: updatedFormData.image_url,
            images: updatedFormData.images,
            is_white_background: updatedFormData.is_white_background,
            order: updatedFormData.order || references.length
          }]);
        
        if (error) throw error;
        
        setSuccessMessage('Yeni referans başarıyla eklendi!');
        
        // Formu sıfırla
        resetForm();
      }
      
      // Referansları yeniden yükle
      fetchReferences();
    } catch (error: any) {
      console.error('Referans kaydedilirken hata:', error.message);
      setError('Referans kaydedilirken bir hata oluştu: ' + error.message);
    }
  };

  // Görsel silme
  const handleRemoveImage = (imageId: string) => {
    if (!formData.images) return;
    
    // Formda güncelleme yap
    const updatedImages = formData.images.filter(img => img.id !== imageId);
    
    let updatedImageUrl = formData.image_url;
    // Eğer ana görsel silindiyse ve başka görseller varsa ilkini ana görsel yap
    if (updatedImages.length > 0 && formData.images.find(img => img.id === imageId)?.url === formData.image_url) {
      updatedImageUrl = updatedImages[0].url;
    } else if (updatedImages.length === 0) {
      updatedImageUrl = '';
    }
    
    setFormData({
      ...formData,
      images: updatedImages,
      image_url: updatedImageUrl
    });
  };

  // Ana görsel yapma
  const handleSetMainImage = (imageId: string) => {
    if (!formData.images) return;
    
    const selectedImage = formData.images.find(img => img.id === imageId);
    if (selectedImage) {
      setFormData({
        ...formData,
        image_url: selectedImage.url
      });
    }
  };

  // Referans silme
  const handleDelete = async (id: string) => {
    if (window.confirm('Bu referansı silmek istediğinize emin misiniz?')) {
      try {
        setError(null);
        
        // Veritabanından referansı sil
        const { error } = await supabase
          .from('references')
          .delete()
          .match({ id });
        
        if (error) throw error;
        
        setSuccessMessage('Referans başarıyla silindi!');
        
        // Referansları yeniden yükle
        fetchReferences();
      } catch (error: any) {
        console.error('Referans silinirken hata:', error.message);
        setError('Referans silinirken bir hata oluştu: ' + error.message);
      }
    }
  };

  // Toplu referans silme
  const handleBulkDelete = async () => {
    if (selectedItems.length === 0) return;
    
    if (window.confirm(`${selectedItems.length} referansı silmek istediğinize emin misiniz?`)) {
      try {
        setError(null);
        
        // Veritabanından seçili referansları sil
        const { error } = await supabase
          .from('references')
          .delete()
          .in('id', selectedItems);
        
        if (error) throw error;
        
        setSuccessMessage(`${selectedItems.length} referans başarıyla silindi!`);
        setSelectedItems([]);
        setSelectMode(false);
        
        // Referansları yeniden yükle
        fetchReferences();
      } catch (error: any) {
        console.error('Referanslar silinirken hata:', error.message);
        setError('Referanslar silinirken bir hata oluştu: ' + error.message);
      }
    }
  };

  // Toplu arkaplan rengini değiştirme
  const handleBulkChangeBackground = async (isWhite: boolean) => {
    if (selectedItems.length === 0) return;
    
    try {
      setError(null);
      
      // Veritabanında seçili referansların arkaplan özelliğini güncelle
      const { error } = await supabase
        .from('references')
        .update({ is_white_background: isWhite })
        .in('id', selectedItems);
      
      if (error) throw error;
      
      setSuccessMessage(`${selectedItems.length} referansın arkaplanı ${isWhite ? 'beyaz' : 'siyah'} olarak güncellendi!`);
      
      // Referansları yeniden yükle
      fetchReferences();
    } catch (error: any) {
      console.error('Referanslar güncellenirken hata:', error.message);
      setError('Referanslar güncellenirken bir hata oluştu: ' + error.message);
    }
  };

  // Referans düzenleme
  const handleEdit = (reference: Reference) => {
    setFormData({
      image_url: reference.image_url,
      images: reference.images,
      is_white_background: reference.is_white_background,
      order: reference.order || 0
    });
    setEditId(reference.id);
    setIsEditing(true);
    setPreviewUrls([]);
    setSuccessMessage(null);
    
    // Sayfa başına kaydır
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Formu sıfırlama
  const resetForm = () => {
    setFormData(initialFormState);
    setSelectedFiles([]);
    setIsEditing(false);
    setEditId(null);
    setPreviewUrls([]);
    setError(null);
    setUploadProgress(0);
  };

  // Öğe seçme/seçimini kaldırma
  const toggleItemSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(itemId => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  // Tümünü seç/seçimi kaldır
  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(references.map(ref => ref.id));
    }
  };

  // Seçim modunu aç/kapat
  const toggleSelectMode = () => {
    setSelectMode(!selectMode);
    if (!selectMode) {
      setSelectedItems([]);
    }
  };

  return (
    <div className="reference-manager admin-section">
      <div className="section-header">
        <h2>{isEditing ? 'Referans Düzenle' : 'Yeni Referans Ekle'}</h2>
        {isEditing && (
          <button 
            className="cancel-button" 
            onClick={resetForm}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
            İptal
          </button>
        )}
      </div>
      
      {error && (
        <div className="error-message">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
          </svg>
          {error}
        </div>
      )}
      
      {successMessage && (
        <div className="success-message">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
          </svg>
          {successMessage}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="reference-form">
        <div className="form-row">
          <div className="form-group">
            <label>Görsel Yükle</label>
            <input 
              type="file" 
              accept="image/*" 
              onChange={handleFileChange}
              multiple
              className="file-input" 
            />
            {isUploading && (
              <div className="progress-container">
                <div className="progress-bar">
                  <div 
                    className="progress" 
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <span className="progress-text">{uploadProgress}% Yüklendi</span>
              </div>
            )}
          </div>
          
          <div className="form-group">
            <label>Görsel Önizleme</label>
            <div className="image-preview">
              {previewUrls.length > 0 ? (
                previewUrls.map((url, index) => (
                  <img 
                    key={index}
                    src={url} 
                    alt="Seçilen görsel önizlemesi" 
                    className="preview-image" 
                  />
                ))
              ) : formData.image_url ? (
                <img 
                  src={formData.image_url} 
                  alt="Mevcut görsel" 
                  className="preview-image" 
                />
              ) : (
                <div className="no-image">Görsel seçilmedi</div>
              )}
            </div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group">
            <label>Arka Plan Rengi</label>
            <div className="color-options">
              <label className={`color-option ${!formData.is_white_background ? 'active' : ''}`}>
                <input
                  type="radio"
                  name="background"
                  checked={!formData.is_white_background}
                  onChange={() => setFormData({...formData, is_white_background: false})}
                />
                <span className="color-preview black"></span>
                <span>Siyah</span>
              </label>
              
              <label className={`color-option ${formData.is_white_background ? 'active' : ''}`}>
                <input
                  type="radio"
                  name="background"
                  checked={formData.is_white_background}
                  onChange={() => setFormData({...formData, is_white_background: true})}
                />
                <span className="color-preview white"></span>
                <span>Beyaz</span>
              </label>
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="order">Sıra Numarası</label>
            <input
              type="number"
              id="order"
              name="order"
              value={formData.order}
              onChange={handleInputChange}
              min="0"
              className="form-control"
            />
            <small>Düşük sayılar başta gösterilir. Boş bırakırsanız sona eklenir.</small>
          </div>
        </div>
        
        {formData.images && formData.images.length > 0 && (
          <div className="form-row">
            <div className="form-group">
              <label>Yüklenen Görseller <span className="image-count">{formData.images.length}</span></label>
              <div className="image-list">
                {formData.images.map((image) => (
                  <div 
                    key={image.id} 
                    className={`image-item ${image.url === formData.image_url ? 'main-image' : ''}`}
                  >
                    <img src={image.url} alt="Görsel" />
                    <button 
                      type="button"
                      className="remove-image-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveImage(image.id);
                      }}
                      title="Görseli Sil"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                      </svg>
                    </button>
                    {image.url !== formData.image_url && (
                      <button 
                        type="button"
                        className="set-main-image-button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSetMainImage(image.id);
                        }}
                        title="Ana Görsel Yap"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                        </svg>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        <div className="form-buttons">
          <button 
            type="submit" 
            className="save-button" 
            disabled={isUploading || (!formData.image_url && !selectedFiles.length)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
            </svg>
            {isEditing ? 'Güncelle' : 'Kaydet'}
          </button>
        </div>
      </form>
      
      <div className="section-header mt-4">
        <h2>Mevcut Referanslar</h2>
        <div className="bulk-actions">
          <button 
            onClick={toggleSelectMode} 
            className={`bulk-action-button ${selectMode ? 'active' : ''}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"/>
            </svg>
            {selectMode ? 'Seçim Modunu Kapat' : 'Toplu İşlem'}
          </button>
          
          {selectMode && (
            <>
              <div className="selection-info">
                {selectedItems.length > 0 ? (
                  <span>{selectedItems.length} öğe seçildi</span>
                ) : (
                  <span>Seçim yapılmadı</span>
                )}
              </div>
              
              <div className="bulk-action-controls">
                <button 
                  className="bulk-select-all"
                  onClick={toggleSelectAll}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                  </svg>
                  {allSelected ? 'Tüm Seçimleri Kaldır' : 'Tümünü Seç'}
                </button>
                
                <button 
                  className="bulk-white-bg"
                  onClick={() => handleBulkChangeBackground(true)}
                  disabled={selectedItems.length === 0}
                >
                  <span className="color-preview white"></span>
                  Beyaz Yap
                </button>
                
                <button 
                  className="bulk-black-bg"
                  onClick={() => handleBulkChangeBackground(false)}
                  disabled={selectedItems.length === 0}
                >
                  <span className="color-preview black"></span>
                  Siyah Yap
                </button>
                
                <button 
                  className="bulk-delete"
                  onClick={handleBulkDelete}
                  disabled={selectedItems.length === 0}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                  </svg>
                  Seçilenleri Sil
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
          <p>Referanslar yükleniyor...</p>
        </div>
      ) : references.length === 0 ? (
        <div className="no-data">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
          </svg>
          <p>Henüz referans eklenmemiş.</p>
          <p className="no-data-hint">Referans eklemek için yukarıdaki formu kullanabilirsiniz.</p>
        </div>
      ) : (
        <div className="reference-grid">
          {references.map((reference) => (
            <div 
              key={reference.id} 
              className={`reference-card ${reference.is_white_background ? 'white-bg' : 'black-bg'} ${selectedItems.includes(reference.id) ? 'selected' : ''}`}
            >
              {selectMode && (
                <div className="select-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(reference.id)}
                    onChange={() => toggleItemSelection(reference.id)}
                    id={`select-${reference.id}`}
                  />
                  <label htmlFor={`select-${reference.id}`}></label>
                </div>
              )}
              
              <div className="reference-image">
                <img src={reference.image_url} alt="Referans" />
                {reference.images && reference.images.length > 1 && (
                  <div className="image-count-badge" title="Toplam görsel sayısı">
                    {reference.images.length}
                  </div>
                )}
              </div>
              <div className="reference-info">
                <div className="reference-detail">
                  <strong>Sıra No:</strong> {reference.order !== null ? reference.order : 'Belirtilmemiş'}
                </div>
                <div className="reference-detail">
                  <strong>Arkaplan:</strong> {reference.is_white_background ? 'Beyaz' : 'Siyah'}
                </div>
              </div>
              <div className="reference-actions">
                <button 
                  className="edit-button"
                  onClick={() => handleEdit(reference)}
                  title="Düzenle"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                  </svg>
                </button>
                <button 
                  className="delete-button"
                  onClick={() => handleDelete(reference.id)}
                  title="Sil"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReferenceManager;
