import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabase/supabaseClient';
import './Dashboard.css';
import ReferenceManager from './ReferenceManager';
import InboxManager from './InboxManager';
import BlogManager from './BlogManager';
import './BlogManager.css';

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState('dashboard');

  useEffect(() => {
    // Kullanıcının oturum açıp açmadığını kontrol et
    const checkUser = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        if (data.session) {
          setUser(data.session.user);
        } else {
          // Kullanıcı giriş yapmamışsa, login sayfasına yönlendir
          window.location.href = '/admin';
        }
      } catch (error) {
        console.error('Oturum kontrolü hatası:', error);
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, []);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      window.location.href = '/admin';
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  };

  // İçeriği seçili menü öğesine göre render etme
  const renderContent = () => {
    switch (activeMenuItem) {
      case 'dashboard':
        return (
          <>
            <div className="stats-container">
              <div className="stat-card">
                <div className="stat-icon clients">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                  </svg>
                </div>
                <div className="stat-info">
                  <h3>12</h3>
                  <p>Müşteriler</p>
                </div>
              </div>
              
              <div className="stat-card">
                <div className="stat-icon references">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                    <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"/>
                  </svg>
                </div>
                <div className="stat-info">
                  <h3>14</h3>
                  <p>Referanslar</p>
                </div>
              </div>
              
              <div className="stat-card">
                <div className="stat-icon blog">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                    <path d="M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM4 18H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45.99 1 .99h16c.55 0 1-.45 1-1s-.45-1-1-1z"/>
                  </svg>
                </div>
                <div className="stat-info">
                  <h3>6</h3>
                  <p>Blog Yazıları</p>
                </div>
              </div>
              
              <div className="stat-card">
                <div className="stat-icon messages">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
                  </svg>
                </div>
                <div className="stat-info">
                  <h3>12</h3>
                  <p>Mesajlar</p>
                </div>
              </div>
            </div>
            
            <div className="dashboard-main-content">
              <div className="activity-section">
                <div className="section-header">
                  <h2>Son Aktiviteler</h2>
                  <button className="view-all-btn">Tümünü Gör</button>
                </div>
                
                <div className="activity-list">
                  <div className="activity-item">
                    <div className="activity-icon user">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="white">
                        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                      </svg>
                    </div>
                    <div className="activity-content">
                      <h3>Yeni müşteri eklendi</h3>
                      <p>Ahmet Yılmaz</p>
                      <span className="activity-time">5 dakika önce</span>
                    </div>
                  </div>
                  
                  <div className="activity-item">
                    <div className="activity-icon message">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="white">
                        <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
                      </svg>
                    </div>
                    <div className="activity-content">
                      <h3>Yeni mesaj alındı</h3>
                      <p>Ayşe Demir gönderdi</p>
                      <span className="activity-time">2 saat önce</span>
                    </div>
                  </div>
                  
                  <div className="activity-item">
                    <div className="activity-icon reference">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="white">
                        <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"/>
                      </svg>
                    </div>
                    <div className="activity-content">
                      <h3>Yeni referans eklendi</h3>
                      <p>Arçelik referansı eklendi</p>
                      <span className="activity-time">1 gün önce</span>
                    </div>
                  </div>
                  
                  <div className="activity-item">
                    <div className="activity-icon blog">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="white">
                        <path d="M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM4 18H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45.99 1 .99h16c.55 0 1-.45 1-1s-.45-1-1-1z"/>
                      </svg>
                    </div>
                    <div className="activity-content">
                      <h3>Yeni blog yazısı eklendi</h3>
                      <p>"Dijital Pazarlama Trendleri" yazısı</p>
                      <span className="activity-time">3 gün önce</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="quick-actions-section">
                <div className="section-header">
                  <h2>Hızlı İşlemler</h2>
                </div>
                
                <div className="actions-grid">
                  <button className="action-button" onClick={() => setActiveMenuItem('references')}>
                    <div className="action-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                        <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"/>
                      </svg>
                    </div>
                    <div className="action-text">
                      <h3>Referans Ekle</h3>
                      <p>Yeni referans eklemek için tıklayın</p>
                    </div>
                  </button>
                  
                  <button className="action-button" onClick={() => setActiveMenuItem('blog')}>
                    <div className="action-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                        <path d="M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM4 18H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45.99 1 .99h16c.55 0 1-.45 1-1s-.45-1-1-1z"/>
                      </svg>
                    </div>
                    <div className="action-text">
                      <h3>Blog Yazısı Ekle</h3>
                      <p>Yeni blog yazısı eklemek için tıklayın</p>
                    </div>
                  </button>
                  
                  <button className="action-button" onClick={() => setActiveMenuItem('inbox')}>
                    <div className="action-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#ff5f00">
                        <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
                      </svg>
                    </div>
                    <div className="action-text">
                      <h3>Mesajları Kontrol Et</h3>
                      <p>Gelen mesajları görüntülemek için tıklayın</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      case 'references':
        return <ReferenceManager user={user} />;
      case 'blog':
        return <BlogManager user={user} />;
      case 'inbox':
        return <InboxManager />;
      default:
        return <div>İçerik bulunamadı.</div>;
    }
  };
  
  if (loading) {
    return (
      <div className="dashboard-loading">
        <div className="loading-spinner"></div>
        <p>Yükleniyor...</p>
      </div>
    );
  }

  return (
    <div className="admin-dashboard">
      <div className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2>Daktilo<span>Dijital</span></h2>
          <p>Yönetim Paneli</p>
        </div>
        
        <nav className="sidebar-menu">
          <ul>
            <li 
              className={activeMenuItem === 'dashboard' ? 'active' : ''} 
              onClick={() => setActiveMenuItem('dashboard')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8v-6h-8v6zm0-16v6h8V1h-8z"/>
              </svg>
              <span>Dashboard</span>
            </li>
            <li 
              className={activeMenuItem === 'references' ? 'active' : ''} 
              onClick={() => setActiveMenuItem('references')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
              </svg>
              <span>Referanslar</span>
            </li>
            <li 
              className={activeMenuItem === 'blog' ? 'active' : ''} 
              onClick={() => setActiveMenuItem('blog')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <path d="M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM4 18H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1zM20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45.99 1 .99h16c.55 0 1-.45 1-1s-.45-1-1-1z"/>
              </svg>
              <span>Blog</span>
            </li>
            <li 
              className={activeMenuItem === 'inbox' ? 'active' : ''} 
              onClick={() => setActiveMenuItem('inbox')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
              </svg>
              <span>Gelen Mailler</span>
            </li>
          </ul>
        </nav>
        
        <div className="sidebar-footer">
          <button className="logout-button" onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"/>
            </svg>
            <span>Çıkış Yap</span>
          </button>
        </div>
      </div>
      
      <div className="dashboard-content">
        <div className="dashboard-header">
          <h1>Hoş Geldiniz, {user?.email}</h1>
          <div className="header-actions">
            <div className="search-box">
              <input 
                type="text" 
                className="admin-form-control" 
                placeholder="Ara..." 
              />
            </div>
            <div className="user-profile">
              <div className="avatar">{user?.email.substring(0, 2).toUpperCase()}</div>
              <div className="user-info">
                <p className="user-name">{user?.email}</p>
                <p className="user-role">Yönetici</p>
              </div>
            </div>
          </div>
        </div>
        
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
