import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabase/supabaseClient';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './BlogPage.css';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  featured_image: string;
  category_id?: string;
  created_at: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

const BlogPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const postsPerPage = 9;

  useEffect(() => {
    fetchCategories();
    fetchPosts();
  }, [selectedCategory, currentPage]);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_categories')
        .select('id, name, slug');

      if (error) throw error;
      setCategories(data || []);
    } catch (error) {
      console.error('Kategoriler yüklenirken hata:', error);
    }
  };

  const fetchPosts = async () => {
    try {
      setLoading(true);
      
      // Toplam yazı sayısını al
      let query = supabase
        .from('blog_posts')
        .select('id', { count: 'exact' })
        .eq('is_published', true);
      
      if (selectedCategory !== 'all') {
        query = query.eq('category_id', selectedCategory);
      }
      
      const countResult = await query;
      setTotalPosts(countResult.count || 0);
      
      // Yazıları getir
      let postsQuery = supabase
        .from('blog_posts')
        .select('id, title, slug, excerpt, featured_image, category_id, created_at')
        .eq('is_published', true)
        .order('created_at', { ascending: false })
        .range((currentPage - 1) * postsPerPage, currentPage * postsPerPage - 1);
      
      if (selectedCategory !== 'all') {
        postsQuery = postsQuery.eq('category_id', selectedCategory);
      }
      
      const { data, error } = await postsQuery;
      
      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Blog yazıları yüklenirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryName = (categoryId: string | undefined): string => {
    if (!categoryId) return 'Genel';
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Genel';
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategory(categoryId);
    setCurrentPage(1); // Kategori değiştiğinde ilk sayfaya dön
  };

  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="blog-pagination">
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="pagination-button"
          aria-label="Önceki sayfa"
        >
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M19 12H5M12 19l-7-7 7-7" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`pagination-number ${currentPage === page ? 'active' : ''}`}
            aria-label={`Sayfa ${page}`}
            aria-current={currentPage === page ? 'page' : undefined}
          >
            {page}
          </button>
        ))}
        
        <button 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="pagination-button"
          aria-label="Sonraki sayfa"
        >
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M5 12h14M12 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div className="blog-page">
      <Navbar />
      
      <div className="blog-header">
        <div className="blog-header-content">
          <h1>Blog</h1>
          <p>Dijital Dünya Hakkında Yazılarımız</p>
        </div>
      </div>
      
      <div className="blog-container">
        <div className="blog-filters">
          <div className="category-filter">
            <button 
              className={`category-button ${selectedCategory === 'all' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('all')}
            >
              Tümü
            </button>
            {categories.map(category => (
              <button 
                key={category.id}
                className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
                onClick={() => handleCategoryChange(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
        
        {loading ? (
          <div className="blog-loading">
            <div className="spinner"></div>
            <p>Blog yazıları yükleniyor...</p>
          </div>
        ) : posts.length === 0 ? (
          <div className="no-posts">
            <p>Bu kategoride henüz yazı bulunmuyor.</p>
          </div>
        ) : (
          <div className="blog-grid">
            {posts.map(post => (
              <div key={post.id} className="blog-card">
                <Link to={`/blog/${post.slug}`} className="blog-card-link">
                  <div className="blog-card-image">
                    {post.featured_image ? (
                      <img src={post.featured_image} alt={post.title} />
                    ) : (
                      <div className="placeholder-image">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64" fill="#ccc">
                          <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
                        </svg>
                      </div>
                    )}
                    <div className="blog-category">{getCategoryName(post.category_id)}</div>
                  </div>
                  <div className="blog-card-content">
                    <h3 className="blog-card-title">{post.title}</h3>
                    <p className="blog-card-excerpt">
                      {post.excerpt || post.title}
                    </p>
                    <div className="blog-card-footer">
                      <span className="blog-date">{formatDate(post.created_at)}</span>
                      <span className="read-more">Devamını Oku &rarr;</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
        
        {renderPagination()}
      </div>
      
      <Footer />
    </div>
  );
};

export default BlogPage;
