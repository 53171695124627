import React from 'react';
import './PolicyPage.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const CookiePolicy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="policy-page">
        <div className="policy-header">
          <h1>ÇEREZ (COOKIE) POLİTİKASI</h1>
        </div>

        <p>
          Web sitemiz, kullanıcı deneyimini geliştirmek ve analiz yapmak amacıyla çerezlerden (cookies)
          faydalanmaktadır.
        </p>

        <div className="policy-section">
          <h2 className="section-title">1. Çerez Nedir?</h2>
          <p>
            Çerezler, web sitelerinin tarayıcınıza yerleştirdiği küçük veri dosyalarıdır. 
            Bu dosyalar, sitenin sizi tanımasını ve deneyiminizi kişiselleştirmesini sağlar.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">2. Kullanılan Çerez Türleri</h2>
          <ul>
            <li>Zorunlu Çerezler: Site temel işlevleri için gereklidir.</li>
            <li>Performans Çerezleri: Ziyaretçi trafiğini analiz eder.</li>
            <li>Reklam Çerezleri: İlgi alanlarınıza uygun içerik sunmak için kullanılır.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="section-title">3. Çerezleri Nasıl Kontrol Edebilirsiniz?</h2>
          <p>
            Tarayıcınız üzerinden çerezleri kontrol edebilir veya tamamen silebilirsiniz. 
            Ancak bazı çerezleri devre dışı bırakmanız, site deneyiminizi olumsuz etkileyebilir.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookiePolicy;
