import React from 'react';
import './PolicyPage.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const TermsOfUse: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="policy-page">
        <div className="policy-header">
          <h1>KULLANIM KOŞULLARI</h1>
          <div className="policy-date">Güncelleme Tarihi: 5.4.2025</div>
        </div>

        <p>
          Bu web sitesine erişerek veya kullanarak, aşağıda belirtilen koşulları kabul etmiş sayılırsınız. 
          Lütfen dikkatlice okuyunuz.
        </p>

        <div className="policy-section">
          <h2 className="section-title">1. Hizmet Tanımı</h2>
          <p>
            Daktilo Dijital Ajans, dijital pazarlama, sosyal medya yönetimi, içerik üretimi ve benzeri hizmetler sunmaktadır. 
            Bu site, ajansın hizmetlerini tanıtmak ve iletişim sağlamak amacıyla kullanılmaktadır.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">2. Fikri Mülkiyet Hakları</h2>
          <p>
            Web sitemizde yer alan tüm yazılı içerik, görsel, logo, marka ve tasarımlar Daktilo Dijital Ajans'a aittir. 
            İzinsiz kullanılamaz, kopyalanamaz, dağıtılamaz.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">3. Kullanıcı Yükümlülükleri</h2>
          <p>Web sitemizi kullanırken;</p>
          <ul>
            <li>Yasalara ve genel ahlak kurallarına uymakla,</li>
            <li>Diğer kullanıcıların haklarına saygılı olmakla,</li>
            <li>Zararlı yazılım, spam veya kötü niyetli veri gönderiminde bulunmamakla</li>
          </ul>
          <p>yükümlüsünüz.</p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">4. Sorumluluk Reddi</h2>
          <p>
            Web sitemizde sunulan bilgiler güncel ve doğru olmaya çalışılsa da, içeriğin tamlığı veya doğruluğu garanti edilmez. 
            Site üzerinden verilen bilgilere dayanarak yapılan işlemlerden doğabilecek zararlardan Daktilo Dijital Ajans sorumlu tutulamaz.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">5. Değişiklik Hakkı</h2>
          <p>
            Daktilo Dijital Ajans, bu koşulları önceden haber vermeksizin değiştirme hakkını saklı tutar. 
            Değişiklikler, yayım tarihi itibariyle geçerli olur.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
