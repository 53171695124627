import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../supabase/supabaseClient';

export type BlogPost = {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  content: string;
  featured_image: string;
  category_id: string;
  is_published: boolean;
  created_at: string;
  updated_at: string;
  author: string;
  meta_title: string;
  meta_description: string;
  keywords: string;
};

export type BlogCategory = {
  id: string;
  name: string;
  slug: string;
  description: string;
  created_at: string;
  updated_at: string;
};

type BlogManagerProps = {
  user: any; // Supabase User
};

const BlogManager: React.FC<BlogManagerProps> = ({ user }) => {
  // Ana bileşen state'leri
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [activeView, setActiveView] = useState<'list' | 'editor' | 'categories'>('list');
  
  // Blog List için state'ler
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<string>('all');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  
  // Blog Editor için state'ler
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [isPublished, setIsPublished] = useState(false);
  const [featuredImage, setFeaturedImage] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [excerpt, setExcerpt] = useState('');
  const [slug, setSlug] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  const [uploading, setUploading] = useState(false);
  
  // Kategori yönetimi için state'ler
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');
  const [editingCategory, setEditingCategory] = useState<BlogCategory | null>(null);
  
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchPosts();
    fetchCategories();
  }, []);
  
  // Post seçildiğinde editor state'lerini güncelleyelim
  useEffect(() => {
    if (selectedPost) {
      setTitle(selectedPost.title || '');
      setContent(selectedPost.content || '');
      setCategoryId(selectedPost.category_id || '');
      setIsPublished(selectedPost.is_published || false);
      setFeaturedImage(selectedPost.featured_image || '');
      setExcerpt(selectedPost.excerpt || '');
      setSlug(selectedPost.slug || '');
      setMetaTitle(selectedPost.meta_title || '');
      setMetaDescription(selectedPost.meta_description || '');
      setKeywords(selectedPost.keywords || '');
    } else {
      // Yeni post için temizle
      setTitle('');
      setContent('');
      setCategoryId('');
      setIsPublished(false);
      setFeaturedImage('');
      setExcerpt('');
      setSlug('');
      setMetaTitle('');
      setMetaDescription('');
      setKeywords('');
      setImageFile(null);
    }
  }, [selectedPost]);

  // Görsel işlemleri
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          setFeaturedImage(event.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  
  const uploadImage = async (): Promise<string> => {
    if (!imageFile) return featuredImage;
    
    setUploading(true);
    try {
      const fileExt = imageFile.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${fileExt}`;
      const filePath = `blog/${fileName}`;
      
      const { error: uploadError } = await supabase.storage
        .from('blog_images')
        .upload(filePath, imageFile);
        
      if (uploadError) throw uploadError;
      
      const { data } = supabase.storage
        .from('blog_images')
        .getPublicUrl(filePath);
        
      setUploading(false);
      return data.publicUrl;
    } catch (error) {
      console.error('Görsel yüklenirken hata oluştu:', error);
      setUploading(false);
      throw error;
    }
  };
  
  // Slug oluşturma
  const createSlug = (text: string): string => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')       // Boşlukları tire ile değiştir
      .replace(/[^\w\-ğüşıöçĞÜŞİÖÇ]+/g, '')  // Alfanümerik olmayan karakterleri kaldır (Türkçe karakterler hariç)
      .replace(/\-\-+/g, '-')     // Çoklu tireleri tek tire ile değiştir
      .replace(/^-+/, '')         // Baştaki tireleri kaldır
      .replace(/-+$/, '')         // Sondaki tireleri kaldır
      .replace(/ğ/g, 'g')         // Türkçe karakterleri değiştir
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/Ğ/g, 'g')
      .replace(/Ü/g, 'u')
      .replace(/Ş/g, 's')
      .replace(/İ/g, 'i')
      .replace(/Ö/g, 'o')
      .replace(/Ç/g, 'c');
  };
  
  // Başlık değiştiğinde otomatik slug oluştur
  useEffect(() => {
    if (title && (!selectedPost || !slug)) {
      const generatedSlug = createSlug(title);
      setSlug(generatedSlug);
    }
  }, [title, selectedPost, slug]);

  const fetchPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (error) throw error;
      
      setPosts(data || []);
    } catch (error) {
      console.error('Blog yazıları getirilirken hata oluştu:', error);
    }
  };
  
  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_categories')
        .select('*')
        .order('name', { ascending: true });
        
      if (error) throw error;
      
      setCategories(data || []);
    } catch (error) {
      console.error('Kategoriler getirilirken hata oluştu:', error);
    }
  };
  
  const handleCreatePost = () => {
    setSelectedPost(null);
    setActiveView('editor');
  };
  
  const handleEditPost = (post: BlogPost) => {
    setSelectedPost(post);
    setActiveView('editor');
  };
  
  const handleDeletePost = async (postId: string) => {
    if (!window.confirm('Bu blog yazısını silmek istediğinizden emin misiniz?')) return;
    
    try {
      const { error } = await supabase
        .from('blog_posts')
        .delete()
        .eq('id', postId);
        
      if (error) throw error;
      
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Blog yazısı silinirken hata oluştu:', error);
      alert('Blog yazısı silinemedi.');
    }
  };
  
  const handleCancelEdit = () => {
    setSelectedPost(null);
    setActiveView('list');
  };
  
  const handleSavePost = async (post: Partial<BlogPost>, isNew: boolean) => {
    try {
      setUploading(true);
      
      let imageUrl = featuredImage;
      if (imageFile) {
        imageUrl = await uploadImage();
      }
      
      const postData = {
        ...post,
        title,
        content,
        category_id: categoryId,
        is_published: isPublished,
        featured_image: imageUrl,
        excerpt,
        slug,
        meta_title: metaTitle,
        meta_description: metaDescription,
        keywords
      };
      
      let result;
      
      if (isNew) {
        result = await supabase.from('blog_posts').insert([
          { 
            ...postData,
            author: user?.email || 'admin',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }
        ]);
      } else {
        result = await supabase
          .from('blog_posts')
          .update({ 
            ...postData, 
            updated_at: new Date().toISOString() 
          })
          .eq('id', post.id);
      }

      if (result.error) throw result.error;
      
      fetchPosts();
      setActiveView('list');
      setUploading(false);
    } catch (error) {
      console.error('Blog yazısı kaydedilemedi:', error);
      alert('Blog yazısı kaydedilirken bir hata oluştu.');
      setUploading(false);
    }
  };

  const renderBlogList = () => {
    const getCategoryName = (categoryId: string): string => {
      const category = categories.find(cat => cat.id === categoryId);
      return category ? category.name : 'Kategorisiz';
    };

    const formatDate = (dateString: string): string => {
      return new Date(dateString).toLocaleDateString('tr-TR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };

    const truncateText = (text: string, maxLength: number): string => {
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + '...';
    };

    const filteredPosts = posts
      .filter(post => {
        // Arama filtresi
        if (searchTerm && !post.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        // Kategori filtresi
        if (categoryFilter !== 'all' && post.category_id !== categoryFilter) {
          return false;
        }
        // Yayın durumu filtresi
        if (statusFilter === 'published' && !post.is_published) {
          return false;
        }
        if (statusFilter === 'draft' && post.is_published) {
          return false;
        }
        return true;
      });

    return (
      <div className="blog-list-container">
        <div className="blog-header">
          <h2>Blog Yazıları</h2>
          <div className="blog-actions">
            <button className="primary-button" onClick={handleCreatePost}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
              </svg>
              Yeni Blog Yazısı
            </button>
            <button className="secondary-button" onClick={() => setActiveView('categories')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/>
              </svg>
              Kategorileri Yönet
            </button>
          </div>
        </div>
        
        <div className="blog-filters">
          <div className="search-filter">
            <input
              type="text"
              placeholder="◼️ Blog yazısı ara..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="blog-search-input"
              style={{ color: "#000000", fontWeight: 500, backgroundColor: "#f0f0f0" }}
            />
          </div>
          
          <div className="filter-group">
            <select
              value={categoryFilter}
              onChange={e => setCategoryFilter(e.target.value)}
              className="category-filter"
              style={{ 
                color: "#000000", 
                fontWeight: 500, 
                backgroundColor: "#f0f0f0" 
              }}
            >
              <option value="all">Tüm Kategoriler</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            
            <select
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
              className="status-filter"
            >
              <option value="all">Tüm Yazılar</option>
              <option value="published">Yayında</option>
              <option value="draft">Taslak</option>
            </select>
          </div>
        </div>

        {filteredPosts.length === 0 ? (
          <div className="no-posts">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64" fill="#e0e0e0">
              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
            </svg>
            <h3>Kriterlere uygun blog yazısı bulunamadı</h3>
            <p>Filtreleri değiştirin veya yeni bir yazı ekleyin.</p>
          </div>
        ) : (
          <div className="blog-posts-table">
            <div className="blog-table-header">
              <div className="blog-col title-col">Başlık</div>
              <div className="blog-col category-col">Kategori</div>
              <div className="blog-col date-col">Tarih</div>
              <div className="blog-col status-col">Durum</div>
              <div className="blog-col actions-col">İşlemler</div>
            </div>
            
            <div className="blog-table-body">
              {filteredPosts.map(post => (
                <div key={post.id} className="blog-table-row">
                  <div className="blog-col title-col">
                    <div className="blog-title">
                      {post.featured_image && (
                        <div className="blog-thumbnail">
                          <img src={post.featured_image} alt={post.title} />
                        </div>
                      )}
                      <div className="blog-info">
                        <h3>{post.title}</h3>
                        <p>{truncateText(post.excerpt || '', 80)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="blog-col category-col">{getCategoryName(post.category_id)}</div>
                  <div className="blog-col date-col">{formatDate(post.created_at)}</div>
                  <div className="blog-col status-col">
                    <span className={`status-badge ${post.is_published ? 'published' : 'draft'}`}>
                      {post.is_published ? 'Yayında' : 'Taslak'}
                    </span>
                  </div>
                  <div className="blog-col actions-col">
                    <button 
                      className="edit-button" 
                      onClick={() => handleEditPost(post)}
                      title="Düzenle"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                      </svg>
                    </button>
                    <button 
                      className="delete-button" 
                      onClick={() => handleDeletePost(post.id)}
                      title="Sil"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderBlogEditor = () => {    
    const handleSaveClick = () => {
      const updatedPost: Partial<BlogPost> = {
        ...selectedPost,
        title,
        content,
        category_id: categoryId,
        is_published: isPublished
      };
      
      handleSavePost(updatedPost, !selectedPost);
    };
    
    return (
      <div className="blog-editor">
        <div className="editor-header">
          <h2>{!selectedPost ? 'Yeni Blog Yazısı' : 'Blog Yazısını Düzenle'}</h2>
          <div className="editor-actions">
            <button 
              className="cancel-button" 
              onClick={handleCancelEdit}
            >
              İptal
            </button>
            <button 
              className="save-button"
              onClick={handleSaveClick}
              disabled={uploading}
            >
              {uploading ? 'Kaydediliyor...' : (isPublished ? 'Yayınla' : 'Taslak Olarak Kaydet')}
            </button>
          </div>
        </div>
        
        <div className="editor-layout">
          <div className="editor-main">
            <div className="form-group">
              <label htmlFor="title">Başlık (H1)</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="◼️ Başlık yazınız..."
                style={{ color: "#000000", fontWeight: 500, backgroundColor: "#f0f0f0" }}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="slug">URL Slug</label>
              <input
                type="text"
                id="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                placeholder="◼️ url-slug-ornegi"
                style={{ color: "#000000", fontWeight: 500, backgroundColor: "#f0f0f0" }}
              />
              <small>URL'de görünecek isim. Otomatik oluşturulur, gerekirse düzenleyebilirsiniz.</small>
            </div>
            
            <div className="form-group">
              <label htmlFor="excerpt">Özet</label>
              <textarea
                id="excerpt"
                value={excerpt}
                onChange={(e) => setExcerpt(e.target.value)}
                placeholder="◼️ Blog yazısının kısa özeti"
                rows={3}
                style={{ 
                  color: "#000000", 
                  fontWeight: 500, 
                  backgroundColor: "#f0f0f0" 
                }}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="content">İçerik</label>
              <textarea
                id="content-editor"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="◼️ Blog yazısının içeriğini buraya girin..."
                rows={15}
                style={{ 
                  color: "#000000", 
                  fontWeight: 500, 
                  backgroundColor: "#f0f0f0" 
                }}
              />
            </div>
          </div>
          
          <div className="editor-sidebar">
            <div className="sidebar-section">
              <h3>Yayın Ayarları</h3>
              <div className="form-group">
                <label htmlFor="is_published">Yayın Durumu</label>
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    id="is_published"
                    checked={isPublished}
                    onChange={() => setIsPublished(!isPublished)}
                  />
                  <label htmlFor="is_published" className="toggle-label">
                    <span className="toggle-inner"></span>
                    <span className="toggle-switch-label">{isPublished ? 'Yayında' : 'Taslak'}</span>
                  </label>
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="category">Kategori</label>
                <select
                  id="category"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  style={{ 
                    color: "#000000", 
                    fontWeight: 500, 
                    backgroundColor: "#f0f0f0" 
                  }}
                >
                  <option value="">Kategori Seçin</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="sidebar-section">
              <h3>Öne Çıkan Görsel</h3>
              <div 
                className="featured-image-container"
                onClick={handleImageClick}
                style={{
                  border: "3px dashed #666666",
                  backgroundColor: "#f0f0f0"
                }}
              >
                {featuredImage ? (
                  <img src={featuredImage} alt="Öne çıkan görsel" className="featured-image-preview" />
                ) : (
                  <div className="featured-image-placeholder">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64" fill="#333">
                      <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
                    </svg>
                    <p style={{ color: "#000000", fontWeight: 500 }}>◼️ Görseli yüklemek için tıklayın</p>
                  </div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            
            <div className="sidebar-section">
              <h3>SEO Ayarları</h3>
              <div className="form-group">
                <label htmlFor="meta-title">Meta Başlık (50-60 karakter)</label>
                <input
                  type="text"
                  id="meta-title"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  placeholder="◼️ Arama motorlarında görünecek başlık..."
                  maxLength={60}
                  style={{ color: "#000000", fontWeight: 500, backgroundColor: "#f0f0f0" }}
                />
                <div className="char-count">{metaTitle.length}/60</div>
              </div>
              
              <div className="form-group">
                <label htmlFor="meta-description">Meta Açıklama (150-160 karakter)</label>
                <textarea
                  id="meta-description"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="◼️ SEO için Meta Açıklama"
                  rows={3}
                  maxLength={160}
                  style={{ 
                    color: "#000000", 
                    fontWeight: 500, 
                    backgroundColor: "#f0f0f0" 
                  }}
                />
                <div className="char-count">{metaDescription.length}/160</div>
              </div>
              
              <div className="form-group">
                <label htmlFor="keywords">Anahtar Kelimeler (virgülle ayırın)</label>
                <input
                  type="text"
                  id="keywords"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  placeholder="◼️ örnek,anahtar,kelimeler"
                  style={{ color: "#000000", fontWeight: 500, backgroundColor: "#f0f0f0" }}
                />
              </div>
            </div>
            
            <div className="sidebar-section">
              <h3>Önizleme</h3>
              <div className="seo-preview">
                <div className="search-preview">
                  <div className="search-title">{metaTitle || title}</div>
                  <div className="search-url">www.daktilodigital.com/blog/{slug}</div>
                  <div className="search-description">
                    {metaDescription || excerpt || 'Bu blogda henüz bir açıklama bulunmuyor...'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCategoryManager = () => {
    const handleSaveCategory = async (e: React.FormEvent) => {
      e.preventDefault();
      
      if (!newCategoryName.trim()) {
        alert('Kategori adı gerekli!');
        return;
      }
      
      try {
        const categorySlug = createSlug(newCategoryName);
        
        let result;
        
        if (editingCategory) {
          // Kategori güncelleme
          result = await supabase
            .from('blog_categories')
            .update({
              name: newCategoryName,
              slug: categorySlug,
              description: newCategoryDescription,
              updated_at: new Date().toISOString()
            })
            .eq('id', editingCategory.id);
        } else {
          // Yeni kategori ekleme
          result = await supabase
            .from('blog_categories')
            .insert([{
              name: newCategoryName,
              slug: categorySlug,
              description: newCategoryDescription,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            }]);
        }
        
        if (result.error) throw result.error;
        
        // Başarıyla eklendi/güncellendi, formu sıfırla ve kategorileri yenile
        setNewCategoryName('');
        setNewCategoryDescription('');
        setEditingCategory(null);
        fetchCategories();
        
      } catch (error) {
        console.error('Kategori kaydedilemedi:', error);
        alert('Kategori kaydedilirken bir hata oluştu!');
      }
    };
    
    const handleEditCategory = (category: BlogCategory) => {
      setEditingCategory(category);
      setNewCategoryName(category.name);
      setNewCategoryDescription(category.description || '');
    };
    
    const handleDeleteCategory = async (categoryId: string) => {
      if (!window.confirm('Bu kategoriyi silmek istediğinizden emin misiniz? Bu kategorideki blog yazıları kategorisiz kalacaktır.')) {
        return;
      }
      
      try {
        const { error } = await supabase
          .from('blog_categories')
          .delete()
          .eq('id', categoryId);
          
        if (error) throw error;
        
        fetchCategories();
      } catch (error) {
        console.error('Kategori silinemedi:', error);
        alert('Kategori silinirken bir hata oluştu!');
      }
    };

    const handleCancelEdit = () => {
      setEditingCategory(null);
      setNewCategoryName('');
      setNewCategoryDescription('');
    };
    
    return (
      <div className="category-manager">
        <div className="category-header">
          <h2>Blog Kategorileri</h2>
          <button 
            className="secondary-button" 
            onClick={() => setActiveView('list')}
          >
            Blog Yazılarına Dön
          </button>
        </div>
        
        <div className="category-content">
          <div className="category-layout">
            <div className="category-form-section">
              <h3>{editingCategory ? 'Kategori Düzenle' : 'Yeni Kategori Ekle'}</h3>
              <form className="category-form" onSubmit={handleSaveCategory}>
                <div className="form-group">
                  <label htmlFor="category-name">Kategori Adı</label>
                  <input
                    type="text"
                    id="category-name"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="Kategori adı"
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="category-description">Açıklama</label>
                  <textarea
                    id="category-description"
                    value={newCategoryDescription}
                    onChange={(e) => setNewCategoryDescription(e.target.value)}
                    placeholder="Kategori açıklaması (isteğe bağlı)"
                    rows={3}
                  />
                </div>
                
                <div className="form-actions">
                  {editingCategory && (
                    <button 
                      type="button" 
                      className="cancel-button"
                      onClick={handleCancelEdit}
                    >
                      İptal
                    </button>
                  )}
                  <button type="submit" className="save-button">
                    {editingCategory ? 'Kategoriyi Güncelle' : 'Kategori Ekle'}
                  </button>
                </div>
              </form>
            </div>
            
            <div className="category-list-section">
              <h3>Mevcut Kategoriler</h3>
              {categories.length === 0 ? (
                <div className="no-categories">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="#e0e0e0">
                    <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/>
                  </svg>
                  <p>Henüz hiç kategori eklenmemiş</p>
                </div>
              ) : (
                <div className="categories-table">
                  <div className="category-table-header">
                    <div className="category-col name-col">Kategori Adı</div>
                    <div className="category-col slug-col">Slug</div>
                    <div className="category-col actions-col">İşlemler</div>
                  </div>
                  
                  <div className="category-table-body">
                    {categories.map(category => (
                      <div key={category.id} className="category-row">
                        <div className="category-col name-col">
                          <span>{category.name}</span>
                          {category.description && (
                            <small>{category.description}</small>
                          )}
                        </div>
                        <div className="category-col slug-col">{category.slug}</div>
                        <div className="category-col actions-col">
                          <button 
                            className="edit-button" 
                            onClick={() => handleEditCategory(category)}
                            title="Düzenle"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                            </svg>
                          </button>
                          <button 
                            className="delete-button" 
                            onClick={() => handleDeleteCategory(category.id)}
                            title="Sil"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="blog-manager">
      {activeView === 'list' && renderBlogList()}
      {activeView === 'editor' && renderBlogEditor()}
      {activeView === 'categories' && renderCategoryManager()}
    </div>
  );
};

export default BlogManager;
