import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../../supabase/supabaseClient';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './BlogDetailPage.css';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  featured_image: string;
  category_id?: string;
  created_at: string;
  updated_at: string;
  meta_title?: string;
  meta_description?: string;
  keywords?: string;
}

interface RelatedPost {
  id: string;
  title: string;
  slug: string;
  excerpt?: string;
  featured_image?: string;
  created_at: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface BlogDetailPageParams {
  slug: string;
}

const BlogDetailPage: React.FC = () => {
  const { slug } = useParams<keyof BlogDetailPageParams>() as BlogDetailPageParams;
  const [post, setPost] = useState<BlogPost | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<RelatedPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchBlogPost();
  }, [slug]);

  const fetchBlogPost = async () => {
    try {
      setLoading(true);
      
      // Blog yazısını getir
      const { data: postData, error: postError } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .eq('is_published', true)
        .single();
      
      if (postError) throw postError;
      if (!postData) throw new Error('Blog yazısı bulunamadı');
      
      setPost(postData);
      
      // Kategoriyi getir
      if (postData.category_id) {
        const { data: categoryData, error: categoryError } = await supabase
          .from('blog_categories')
          .select('*')
          .eq('id', postData.category_id)
          .single();
        
        if (!categoryError && categoryData) {
          setCategory(categoryData);
          
          // İlgili yazıları getir (aynı kategoriden, farklı yazılar)
          const { data: relatedData, error: relatedError } = await supabase
            .from('blog_posts')
            .select('id, title, slug, excerpt, featured_image, created_at')
            .eq('category_id', postData.category_id)
            .eq('is_published', true)
            .neq('id', postData.id)
            .order('created_at', { ascending: false })
            .limit(3);
          
          if (!relatedError && relatedData) {
            setRelatedPosts(relatedData as RelatedPost[]);
          }
        }
      }
    } catch (error) {
      console.error('Blog yazısı yüklenirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  if (loading) {
    return (
      <div className="blog-detail-page">
        <Navbar />
        <div className="blog-detail-loading">
          <div className="spinner"></div>
          <p>Blog yazısı yükleniyor...</p>
        </div>
        <Footer />
      </div>
    );
  }

  if (!post) {
    return (
      <div className="blog-detail-page">
        <Navbar />
        <div className="blog-detail-not-found">
          <h2>Blog Yazısı Bulunamadı</h2>
          <p>Aradığınız içeriğe ulaşılamadı.</p>
          <Link to="/blog" className="back-to-blog">
            &larr; Blog Sayfasına Dön
          </Link>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="blog-detail-page">
      <Navbar />
      
      <div className="blog-detail-header">
        {post.featured_image && (
          <div 
            className="blog-detail-featured-image" 
            style={{ backgroundImage: `url(${post.featured_image})` }}
          >
            <div className="featured-image-overlay"></div>
          </div>
        )}
        
        <div className="blog-detail-header-content">
          <div className="blog-detail-meta">
            {category && (
              <Link to={`/blog?category=${category.id}`} className="blog-detail-category">
                {category.name}
              </Link>
            )}
            <span className="blog-detail-date">{formatDate(post.created_at)}</span>
          </div>
          <h1 className="blog-detail-title">{post.title}</h1>
          {post.excerpt && <p className="blog-detail-excerpt">{post.excerpt}</p>}
        </div>
      </div>
      
      <div className="blog-detail-container">
        <article className="blog-detail-content">
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </article>
        
        <div className="blog-detail-navigation">
          <Link to="/blog" className="back-to-blog">
            &larr; Blog Sayfasına Dön
          </Link>
        </div>
      </div>
      
      {/* İlgili yazıları footer'ın hemen üstüne taşıdık */}
      {relatedPosts.length > 0 && (
        <div className="related-posts-section">
          <div className="related-posts-container">
            <h3 className="related-posts-title">İlgili Yazılar</h3>
            <div className="related-posts-grid">
              {relatedPosts.map(relatedPost => (
                <div key={relatedPost.id} className="related-post-card">
                  <Link to={`/blog/${relatedPost.slug}`} className="related-post-link">
                    <div className="related-post-image">
                      {relatedPost.featured_image ? (
                        <img src={relatedPost.featured_image} alt={relatedPost.title} />
                      ) : (
                        <div className="placeholder-image">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="#ccc">
                            <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="related-post-content">
                      <h4 className="related-post-title">{relatedPost.title}</h4>
                      <span className="related-post-date">{formatDate(relatedPost.created_at)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      <Footer />
    </div>
  );
};

export default BlogDetailPage;
