import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import SEO from './components/SEO/SEO';
import LocalBusinessSchema from './components/StructuredData/LocalBusinessSchema';
import { supabase } from './supabase/supabaseClient';
// Bileşenleri lazy loading ile yüklüyoruz
const Services = lazy(() => import('./components/Services/Services'));
const DigitalSolutions = lazy(() => import('./components/DigitalSolutions/DigitalSolutions'));
const References = lazy(() => import('./components/References/References'));
const BlogPreview = lazy(() => import('./components/Blog/BlogPreview'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const WhatsAppButton = lazy(() => import('./components/WhatsAppButton/WhatsAppButton'));
const CookieConsent = lazy(() => import('./components/CookieConsent/CookieConsent'));

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    budget: 15000,
    details: ''
  });
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileForm, setShowMobileForm] = useState(false);
  const rangeInputRef = useRef<HTMLInputElement>(null);

  const updateRangeBackground = (value: number) => {
    const rangeInput = rangeInputRef.current;
    if (rangeInput) {
      const min = parseInt(rangeInput.min || '15000'); 
      const max = parseInt(rangeInput.max || '100000');
      const val = parseInt(value.toString());
      const percentage = Math.max(0, Math.min(100, ((val - min) * 100) / (max - min)));
      
      rangeInput.style.background = `linear-gradient(to right, #ff5f00 ${percentage}%, rgba(255, 255, 255, 0.2) ${percentage}%)`;
    }
  };

  useEffect(() => {
    // Ekran boyutunu kontrol et
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);
  
  // Form verisi değiştiğinde range stilini güncelle
  useEffect(() => {
    updateRangeBackground(formData.budget);
  }, [formData.budget]);
  
  // İlk yüklemede range stilini ayarla
  useEffect(() => {
    const timer = setTimeout(() => {
      updateRangeBackground(formData.budget);
    }, 0);
    return () => clearTimeout(timer);
  }, []); // Boş dependency array ile sadece ilk render'da çalışacak
  
  // Form gösterildiğinde body scroll'u engelle
  useEffect(() => {
    if (isMobile && showMobileForm) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobile, showMobileForm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setFormData({
      ...formData,
      budget: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Form verilerini hazırla
      const messageData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        budget: parseInt(formData.budget.toString()), // Kesinlikle sayı olarak gönder
        message: formData.details,
        created_at: new Date().toISOString()
      };
      
      console.log('Gönderilecek veri:', messageData);
      
      // Supabase'e kaydet
      const { data, error } = await supabase
        .from('contact_messages')
        .insert([messageData]);
      
      if (error) {
        console.error('Form gönderilirken hata oluştu:', error);
        console.error('Hata detayı:', JSON.stringify(error));
        alert(`Form gönderilirken bir hata oluştu: ${error.message}`);
        return;
      }
      
      console.log('Form başarıyla gönderildi:', data);
      
      // Formu sıfırla
      setFormData({
        name: '',
        email: '',
        phone: '',
        budget: 15000,
        details: ''
      });
      
      // Kullanıcıya bilgi ver
      alert('Formunuz başarıyla gönderildi. En kısa sürede sizinle iletişime geçeceğiz.');
      setShowMobileForm(false);
    } catch (error) {
      console.error('Beklenmeyen bir hata oluştu:', error);
      if (error instanceof Error) {
        alert(`Form gönderilirken bir hata oluştu: ${error.message}`);
      } else {
        alert('Form gönderilirken bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  };

  const toggleMobileForm = () => {
    setShowMobileForm(!showMobileForm);
  };

  const closeForm = () => {
    setShowMobileForm(false);
  };

  // Form render edilmesi
  const renderContactForm = () => {
    return (
      <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
        {isMobile && (
          <button 
            type="button" 
            className="mobile-form-close" 
            onClick={closeForm}
          >
            ×
          </button>
        )}
        <div className="form-group">
          <label htmlFor="name">İsim Soyisim</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            placeholder="İsminizi giriniz" 
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">E-posta Adresi</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            placeholder="E-posta adresinizi giriniz" 
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="phone">Telefon Numarası</label>
          <input 
            type="tel" 
            id="phone" 
            name="phone" 
            placeholder="Telefon numaranızı giriniz" 
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="budget">Proje Bütçesi: <span className="budget-display">{formData.budget.toLocaleString()} ₺</span></label>
          <input 
            ref={rangeInputRef}
            type="range" 
            id="budget" 
            name="budget" 
            min="15000" 
            max="100000" 
            step="1000" 
            value={formData.budget}
            onChange={handleRangeChange}
            className="custom-range"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="details">Proje Detayları</label>
          <textarea 
            id="details" 
            name="details" 
            placeholder="Projeniz hakkında detaylı bilgi veriniz" 
            value={formData.details}
            onChange={handleInputChange}
            rows={5}
          />
        </div>
        
        <div className="form-group">
          <button type="submit" className="submit-btn">Teklif Al</button>
        </div>
      </form>
    );
  };

  return (
    <div className="App">
      <SEO />
      <LocalBusinessSchema />
      <Navbar />
      <main className="content">
        <section className="hero" id="hero">
          <div className="hero-background">
            <div className="circle circle-1"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-3"></div>
            <div className="circle circle-4"></div>
            <div className="circle circle-5"></div>
            <div className="light light-1"></div>
            <div className="light light-2"></div>
          </div>
          
          <div className="hero-container">
            <div className="hero-text">
              <h2 className="subtitle">Dijital Dönüşümün Öncüsü</h2>
              <h1 className="title">Daktilo Dijital</h1>
              <div className="separator">
                <span></span>
                <i className="dot"></i>
                <span></span>
              </div>
              <p className="description">
                Dijital Dünyayı Sizin İçin Yeniden Tanımlıyoruz. 
                Modern, yenilikçi ve etkileyici çözümlerle 
                markanızı bir adım öne taşıyoruz.
              </p>
              <div className="hero-buttons">
                <a href="#services" className="hero-button primary-button">
                  <span>Hizmetlerimizi İnceleyin</span>
                  <div className="btn-arrow">
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M5 12h14M12 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </a>
                {isMobile && !showMobileForm && (
                  <button onClick={toggleMobileForm} className="hero-button secondary-button mobile-quote-btn">
                    <span>Teklif Al</span>
                    <div className="btn-arrow">
                      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M5 12h14M12 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </button>
                )}
              </div>
            </div>
            
            {/* Masaüstünde form */}
            {!isMobile && (
              <div className="contact-form-card">
                {renderContactForm()}
              </div>
            )}
            
            {/* Mobilde modal form */}
            {isMobile && showMobileForm && (
              <div className="mobile-form-container">
                <div className="mobile-form-card">
                  {renderContactForm()}
                </div>
              </div>
            )}
          </div>
          
          <div className="scroll-indicator">
            <div className="mouse">
              <div className="wheel"></div>
            </div>
            <p>Aşağı Kaydır</p>
          </div>
        </section>
        
        <Suspense fallback={<div className="loading">Yükleniyor...</div>}>
          <section id="services">
            <Services />
          </section>
          <section id="digital-solutions">
            <DigitalSolutions />
          </section>
          <section id="references">
            <References />
          </section>
        </Suspense>
      </main>
      <Suspense fallback={<div className="loading">Yükleniyor...</div>}>
        <section id="blog" className="white-section">
          <BlogPreview />
        </section>
        <Footer />
        <WhatsAppButton phoneNumber="+905446336035" message="Merhaba, bilgi almak istiyorum." />
        <CookieConsent />
      </Suspense>
    </div>
  );
}

export default App;
