import { createClient } from '@supabase/supabase-js';

// .env'den okuma sorunları için doğrudan değerleri kullanabilirsiniz (geliştirme amaçlı)
const supabaseUrl = "https://nrdwudgqohxmlhaldxad.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5yZHd1ZGdxb2h4bWxoYWxkeGFkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDM4NjMwNTgsImV4cCI6MjA1OTQzOTA1OH0.nCu4Yj4GJH43WuzPIiVCTXXz1BI-XtxImUlSoRI3qnk";

// Değerlerin tanımlı olduğunu kontrol et - canlıya alındığında .env'den okumalısınız
if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Supabase URL veya Anon Key tanımlanmamış! Lütfen .env dosyasını kontrol edin.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
