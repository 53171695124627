import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { supabase } from '../supabase/supabaseClient';
import Login from './Auth/Login';
import Dashboard from './Admin/Dashboard';
import App from '../App';
import PrivacyPolicy from './PolicyPages/PrivacyPolicy';
import TermsOfUse from './PolicyPages/TermsOfUse';
import KVKKPolicy from './PolicyPages/KVKKPolicy';
import CookiePolicy from './PolicyPages/CookiePolicy';
import BlogPage from './Blog/BlogPage';
import BlogDetailPage from './Blog/BlogDetailPage';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [session, setSession] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(!!session);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return session ? <>{children}</> : <Navigate to="/admin" replace />;
};

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
        <Route path="/kullanim-kosullari" element={<TermsOfUse />} />
        <Route path="/kvkk-sozlesmesi" element={<KVKKPolicy />} />
        <Route path="/cerez-politikasi" element={<CookiePolicy />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogDetailPage />} />
        {/* Diğer yollar */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
