import React, { useState } from 'react';
import { supabase } from '../../supabase/supabaseClient';
import './Login.css';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      setLoading(true);
      
      // Form doğrulama
      if (!email || !password) {
        throw new Error('Email ve şifre gereklidir.');
      }
      
      // Supabase ile oturum açma
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      
      // Başarılı giriş - kullanıcıyı admin dashboard'a yönlendir
      if (data && data.user) {
        window.location.href = '/dashboard';
      }
    } catch (error: any) {
      setError(error.message || 'Giriş başarısız. Lütfen tekrar deneyin.');
      console.error('Giriş hatası:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <h1>
            Daktilo<span>Dijital</span>
          </h1>
          <h2>Yönetim Paneli</h2>
        </div>

        <form onSubmit={handleLogin} className="login-form">
          {error && <div className="login-error">{error}</div>}
          
          <div className="form-group">
            <label htmlFor="email">E-Posta</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-posta adresiniz"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Şifre</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifreniz"
              required
            />
          </div>
          
          <button 
            type="submit" 
            className="login-button" 
            disabled={loading}
          >
            {loading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
          </button>
        </form>
        
        <div className="login-footer">
          <p> {new Date().getFullYear()} Daktilo Dijital. Tüm hakları saklıdır.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
