import React from 'react';
import './PolicyPage.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="policy-page">
        <div className="policy-header">
          <h1>GİZLİLİK POLİTİKASI</h1>
          <div className="policy-date">Güncelleme Tarihi: 5.4.2025</div>
        </div>

        <p>
          Daktilo Dijital Ajans olarak, kullanıcılarımızın gizliliğine ve kişisel verilerinin korunmasına büyük önem veriyoruz. 
          Bu Gizlilik Politikası, web sitemizi (www.daktilodijital.com) ziyaret ettiğinizde hangi verileri topladığımızı, 
          nasıl kullandığımızı ve koruduğumuzu açıklar.
        </p>

        <div className="policy-section">
          <h2 className="section-title">1. Toplanan Bilgiler</h2>
          <p>Web sitemizi ziyaret ettiğinizde aşağıdaki bilgileri toplayabiliriz:</p>
          <ul>
            <li>İletişim formları aracılığıyla sağladığınız ad, soyad, e-posta adresi ve telefon numarası gibi kişisel bilgiler.</li>
            <li>IP adresi, tarayıcı bilgileri, cihaz türü, sayfa görüntüleme istatistikleri gibi otomatik olarak toplanan veriler.</li>
            <li>Çerezler (cookies) aracılığıyla kullanıcı davranışları.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2 className="section-title">2. Verilerin Kullanım Amaçları</h2>
          <p>Toplanan veriler;</p>
          <ul>
            <li>Size daha iyi hizmet verebilmek,</li>
            <li>Taleplerinize geri dönüş sağlayabilmek,</li>
            <li>Web sitemizi ve hizmetlerimizi geliştirebilmek,</li>
            <li>Yasal yükümlülüklerimizi yerine getirebilmek</li>
          </ul>
          <p>amaçlarıyla kullanılabilir.</p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">3. Verilerin Paylaşımı</h2>
          <p>
            Kişisel verileriniz üçüncü taraflarla, yalnızca yasal yükümlülükler veya hizmet sunumu için gerekli durumlarda paylaşılabilir. 
            Hiçbir şekilde üçüncü şahıslara pazarlama amacıyla satılmaz veya ticari kazanç sağlanmaz.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">4. Güvenlik</h2>
          <p>
            Verilerinizin güvenliği bizim için önemlidir. Bu nedenle, kişisel verilerinizin yetkisiz erişime, kayba veya kötüye 
            kullanıma karşı korunması için gerekli teknik ve idari tedbirleri alıyoruz.
          </p>
        </div>

        <div className="policy-section">
          <h2 className="section-title">5. Haklarınız</h2>
          <p>KVKK ve ilgili mevzuatlar çerçevesinde;</p>
          <ul>
            <li>Hangi verilerin toplandığını öğrenme,</li>
            <li>Bu verilerin düzeltilmesini veya silinmesini talep etme,</li>
            <li>Verilerin işlenmesine itiraz etme</li>
          </ul>
          <p>haklarına sahipsiniz. Taleplerinizi <a href="mailto:info@daktilodijital.com">info@daktilodijital.com</a> adresine iletebilirsiniz.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
